// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comparer-js": () => import("./../../../src/pages/comparer.js" /* webpackChunkName: "component---src-pages-comparer-js" */),
  "component---src-pages-espaces-d-activites-js": () => import("./../../../src/pages/espaces-d-activites.js" /* webpackChunkName: "component---src-pages-espaces-d-activites-js" */),
  "component---src-pages-explorer-js": () => import("./../../../src/pages/explorer.js" /* webpackChunkName: "component---src-pages-explorer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-zone-js": () => import("./../../../src/templates/zone.js" /* webpackChunkName: "component---src-templates-zone-js" */)
}

